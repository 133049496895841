import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import '../css/about.scss'
import ReactPlayer from 'react-player'
import instaSvg from '../images/assets/insta.svg'
import linkedinSvg from '../images/assets/linkedin.svg'
import youtubeSvg from '../images/assets/youtube.svg'
import axios from 'axios'
import config from '../config.json'
import { useLocation } from 'react-router-dom';
import  changeCanonialLink  from '../utils/changeCanonialLink';

import { useTranslation } from 'react-i18next'


function About() {

    const [data, setData] = useState(null)

    const { t, i18n } = useTranslation();

    useEffect(() => {
        axios.get(config['apiUrl'] + 'api/contact')
            .then(res => {
                setData(res.data[0])
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const [dateNow, setDateNow] = useState(new Date().getFullYear())

    // set title and meta description
    useEffect(() => {
        const title = t('about') + ' - Louis Gaday';
        document.title = title;
    }, [t])


    const location = useLocation();
    useEffect(() => {
        changeCanonialLink(location.pathname);
    }, [location]);


    return (
        <>
            <Header page="about" />

            <div className="about-container">
                <h1>{t('about')}</h1>

                <div className="about-grid">
                    <div className="about-video">
                        {data &&
                            <><ReactPlayer
                                url={data.showreel}
                                playing={false}
                                controls={true}
                                light={true}
                                width="100%"
                                height="100%"
                                playsinline
                            />
                                <div>
                                    <h3>{data.showreelTitle}</h3>
                                    <p>{t('by')} <strong>Louis GADAY</strong></p>
                                </div>
                            </>
                        }


                    </div>
                    <div className="about-text">
                        {data && i18n.language ? (
                            data['text_description_about_' + i18n.language] &&
                            typeof data['text_description_about_' + i18n.language] === 'string' ? (
                                data['text_description_about_' + i18n.language].split('\r\n').map((item, key) => (
                                    <p key={key}>{item}</p>
                                ))
                            ) : (
                                <p>Aucune description disponible dans la langue actuelle.</p>
                            )
                        ) : (
                            <p>Chargement...</p>  // Afficher quelque chose en attendant les données
                        )}
                    </div>

                </div>

                <div className="about-footer">
                    <div className='footer-separation'></div>


                    <div className='footer-grid'>

                        <div className='footer-left'>

                            <p onClick={() => window.location.href = '/legal-notices'}>{t('Mentions légales')}</p>
                            <p onClick={() => window.location.href = '/rgpd'}>{t('RGPD')}</p>
                            <p onClick={() => window.location.href = '/site-map'}>{t('Plan du site')}</p>
                        </div>

                        <div className='footer-center'>
                            <p id="lieu"><strong>{t('montpellier')}</strong> - FRANCE</p>
                            <br />
                            <div id='separator'></div>
                            <p id="copyright">© {dateNow} Louis GADAY</p>
                            <p id="realise">{t('site realisé par')} <strong><a href='https://remy-eroes.site'>Rémy EROES</a></strong></p>
                        </div>

                        <div className='footer-right'>

                            <div className='social-media'>
                                <a href='https://www.instagram.com/l.gaday'><img src={instaSvg} alt={"instagram Louis Gaday photographe et videaste Motpellier Occitanie"} /></a>
                                <a href='https://youtube.com/@louisgaday?si=mOBDkmHDZpNEDnJA'><img src={youtubeSvg} alt={"youtube Louis Gaday photographe et videaste Motpellier Occitanie"} /></a>
                                <a href='https://fr.linkedin.com/in/louis-gaday-b32068269'><img src={linkedinSvg} alt={"linkedin Louis Gaday photographe et videaste Motpellier Occitanie"} /></a>
                            </div>
                            {data && <> <address><a href={'mailto:' + data.mail}>{data.mail}</a></address>
                                <address>{data.telephone}</address></>}

                        </div>

                    </div>

                </div>


            </div>




        </>
    )
}

export default About